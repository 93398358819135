import React from "react"
import { motion } from "framer-motion"

import SectionHeader from "components/SectionHeader"
import Button from "components/Button"

import fadeAnimation from "utils/fadeAnimation"

const NewsletterConfirmation = () => {
  return (
    <section className="contact-confirmation">
      <div className="container-fluid">
        <SectionHeader
          title="Dziękujemy"
          content="za zapis do newslettera"
          type="white"
        />
        <div className="row">
          <div className="col-xl-11 offset-xl-1">
            <motion.div className="not-found-content__inner" {...fadeAnimation}>
              <Button to="/" className="button--fill">
                Strona główna
              </Button>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default NewsletterConfirmation
