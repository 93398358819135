import "./styles.scss"

import React, { useState } from "react"
import axios from "axios"
import { navigate } from "gatsby"

import Input from "components/Input"
import Checkbox from "components/Checkbox"
import Button from "components/Button"
import Select from "components/Select"

const ContactForm = () => {
  const formID = "11486"
  const [send, setSend] = useState(false)
  const [subject, setSubject] = useState("")
  const [name, setName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [legalFirst, setLegalFirst] = useState(false)

  const isBrowser = typeof window !== "undefined"
  const location = isBrowser ? window.location.href : null

  const formSubmit = e => {
    e.preventDefault()
    setSend(true)

    let formData = new FormData()

    formData.set("subject", subject)
    formData.set("firstName", name)
    formData.set("lastName", lastName)
    formData.set("email", email)
    formData.set("phone", phone)
    formData.set("message", message)
    formData.set("legalFirst", legalFirst)
    formData.set("pageurl", location)
    formData.set("_wpcf7_unit_tag", '#wpcf7_unit_tag-'+formID)

    axios({
      method: "post",
      url: `https://cms.wallcraft.com.pl/wp-json/contact-form-7/v1/contact-forms/${formID}/feedback/`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(res => {
        console.log("Submit success")
        navigate("/kontakt-potwierdzenie/")
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <form onSubmit={formSubmit} className="form">
      <div className="row">
        <div className="col-md-12">
          <Select
            value={subject}
            onChange={e => setSubject(e?.target?.value)}
            className="select--form"
            required
            options={[
              {
                label: "Wybierz temat rozmowy",
                value: "",
                disabled: true,
              },
              {
                label: "Pytanie ogólne",
                value: "Pytanie ogólne",
              },
              {
                label: "Reklamacja",
                value: "Reklamacja",
              },
              {
                label: "Zwrot",
                value: "Zwrot",
              },
              {
                label: "Współpraca",
                value: "Współpraca",
              },
            ]}
          ></Select>
        </div>
        <div className="col-sm-6">
          <Input
            placeholder="Imię*"
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={e => setName(e.target.value)}
            required
          />
        </div>
        <div className="col-sm-6">
          <Input
            placeholder="Nazwisko*"
            type="text"
            id="lastName"
            name="lastName"
            value={lastName}
            onChange={e => setLastName(e.target.value)}
            required
          />
        </div>
        <div className="col-sm-6">
          <Input
            placeholder="Adres email*"
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="col-sm-6">
          <Input
            placeholder="Numer telefonu*"
            type="number"
            id="phone"
            name="phone"
            value={phone}
            onChange={e => setPhone(e.target.value)}
            required
          />
        </div>
      </div>

      <Input
        placeholder="Treść wiadomosci*"
        type="textarea"
        id="message"
        name="message"
        value={message}
        onChange={e => setMessage(e.target.value)}
        rows="6"
        required
      />

      <Checkbox
        name="legalFirst"
        checked={legalFirst}
        onChange={() => setLegalFirst(!legalFirst)}
        required
      >
        <span>*</span> Wyrażam dobrowolną zgodę na przetwarzanie moich danych
        osobowych przez Wallcraft s.c. Robert Stodulski, Radosław Stodulski z
        siedzibą w Słupsku, ul. Kaszubska 45, REGON: 524037374 (Administrator
        danych), w celu kontaktu bezpośredniego dotyczącego własnych produktów i
        usług. Obowiązek informacyjny zgodnie z art. 13 ogólnego rozporządzenia
        o ochronie danych osobowych (RODO) z dnia 27 kwietnia 2016 r. (Dz. Urz.
        UE L 119 z 04.05.2016): informacje o przetwarzaniu przez nas danych, jak
        również o prawach osób, których dane dotyczą znajdują się w naszej
        Polityce Prywatności.
      </Checkbox>

      <p className="form__info">
        <span>*</span> Pola obowiązkowe
      </p>

      <div className="form__submit">
        <Button type="submit" disabled={!legalFirst}>
          {send === true ? "Wysyłanie.." : "Wyślij zapytanie"}
        </Button>
      </div>
    </form>
  )
}

export default ContactForm
